import { useState, useCallback } from 'react'

export const useLoadingError = (initialLoading = false) => {
  const [isLoading, setIsLoading] = useState(initialLoading)
  const [error, setError] = useState(null)

  const startLoading = useCallback(() => {
    setIsLoading(true)
    setError(null)
  }, [])

  const stopLoading = useCallback(() => {
    setIsLoading(false)
  }, [])

  const setErrorMessage = useCallback((message) => {
    setError(message)
    setIsLoading(false)
  }, [])

  const clearError = useCallback(() => {
    setError(null)
  }, [])

  const handleError = useCallback(
    (err) => {
      const errorMessage =
        err?.status?.message_client || 'Terjadi kesalahan, silakan coba lagi'
      setErrorMessage(errorMessage)
    },
    [setErrorMessage]
  )

  const wrapPromise = useCallback(
    async (promise) => {
      try {
        startLoading()
        const result = await promise
        stopLoading()
        return result
      } catch (err) {
        handleError(err)
        throw err
      }
    },
    [startLoading, stopLoading, handleError]
  )

  return {
    isLoading,
    error,
    startLoading,
    stopLoading,
    setErrorMessage,
    clearError,
    handleError,
    wrapPromise,
  }
}

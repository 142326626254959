import React from 'react'
import { MdPersonOutline, MdOutlineWhatsapp } from 'react-icons/md'
import Form from 'components/forms/Form'
import Input from 'components/forms/Input'
import LoadingButton from 'components/button/LoadingButton'
import Button from 'components/button/Index'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const schema = yup.object().shape({
  name: yup.string().required('Nama wajib diisi'),
  phone: yup
    .string()
    .required('Nomor telepon wajib diisi')
    .min(10, 'Telp minimal 10 karakter'),
})

const ContactFormModal = ({
  showModal,
  onClose,
  contactForm,
  setContactForm,
  onSubmit,
  nameInputRef,
  isLoading,
  error,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: contactForm,
  })

  const onSubmitForm = (data) => {
    const formData = {
      name: data.name,
      phone: data.phone,
    }
    setContactForm(formData)
    onSubmit(formData)
  }

  if (!showModal) return null

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[100]"
      style={{ isolation: 'isolate' }}
    >
      <div className="bg-white rounded-lg p-6 w-[90%] max-w-md">
        <div className="mb-4">
          <h2 className="text-lg font-semibold mb-1">Informasi Pemesan</h2>
          <p className="text-sm">
            lengkapi form ini untuk mendapatkan menu yang sesuai dengan lokasimu
          </p>
        </div>
        <Form
          className="mt-9 mb-9 md:mb-0"
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault()
          }}
        >
          <div className="flex mb-8">
            <div className="mr-2">
              <MdPersonOutline className="w-6 h-6" />
            </div>
            <div className="w-11/12">
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="text"
                    ref={nameInputRef}
                    label="Nama Pemesan"
                    placeholder="Masukkan nama lengkap"
                    error={errors.name && errors.name.message}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex">
            <div className="mr-2">
              <MdOutlineWhatsapp className="w-6 h-6" />
            </div>
            <div className="w-11/12">
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="tel"
                    label="Nomor Whatsapp Pemesan"
                    placeholder="Masukkan nomor whatsapp"
                    error={errors.phone && errors.phone.message}
                  />
                )}
              />
            </div>
          </div>
        </Form>

        <div className="mt-6 flex justify-end space-x-3">
          <Button
            isRounded={true}
            variant="white"
            className="w-1/2 md:w-[128px]"
            onClick={onClose}
          >
            Batal
          </Button>
          <LoadingButton
            className="w-1/2 md:w-[128px]"
            variant="primary"
            isRounded={true}
            onClick={handleSubmit(onSubmitForm)}
            isLoading={isLoading}
          >
            Pilih Menu
          </LoadingButton>
        </div>
        {error && (
          <div className="text-red-500 text-sm pr-2 mt-2 text-right w-full">
            {error}
          </div>
        )}
      </div>
    </div>
  )
}

export default React.memo(ContactFormModal)

/* eslint-disable no-unused-vars */
import LoadingButton from 'components/button/LoadingButton'
import NavAddress from 'components/layouts/fragments/Navbar/NavAddress'
import Tooltip from 'components/layouts/fragments/Tooltip'
import { useMicrositeCartSystem } from 'modules/custom/microsite/useCartSystem'
import { useOrder } from 'modules/custom/microsite/useOrder'
import { useCartSystem } from 'modules/custom/useCartSystem'
import { useFirebaseHook } from 'modules/custom/useFirebaseHook'
import { usePartnerListMutation } from 'modules/Microsite/hook'
import { useFetchDetailMitra } from 'modules/Mitra/hooks'
import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { dateGMT, MONTH_NAMES } from 'utils/date'
import { currency } from 'utils/formatNumber'

import DateTimePicker from './components/DateTimePicker'
import DateCart from './components/fragments/DateCart'
import EmptyCart from './components/fragments/EmptyCart'
import MenuCart from './components/fragments/MenuCart'

const CartMobileWA = () => {
  const params = useParams()
  const {
    loading,
    setLoading,
    cart,
    grandTotal,
    setDateParams,
    setDatePick,
    time,
    setTime,
    addProduct,
    updateQuantity,
    removeByDate,
    removeProduct,
    changeDateCart,
    changeTimeCart,
    handlePayment,
    datePick,
    errorMessage,
  } = useMicrositeCartSystem()

  const [paramsFrom, setParamsFrom] = useSearchParams()
  const dataparam = paramsFrom.get('ref')
  const token = localStorage.getItem('token')
  const navigate = useNavigate()

  const { differentDate } = useOrder()
  const { setClickPayment } = useFirebaseHook()
  const detailMitra = useFetchDetailMitra(location.lat, location.lng, params.id)

  const [alertMenuUtama, setAlertMenuUtama] = useState(false)
  const [alertMinOrder, setAlertMinOrder] = useState(false)
  const [alertMenuNotAvail, setAlertMenuNotAvail] = useState(false)

  const [lateOrder, setLateOrder] = useState(false)
  const [dateLate, setDateLate] = useState('')

  // const minOrder = partner.info_min_order
  const findMenu = cart.flatMap((menu) => menu.menus)

  const cekData = cart.map((data) => data.menus)

  const checkNotAvail = findMenu.findIndex((x) => x.available == false)
  const [dateMinimum, setDateMinimum] = useState(Date.now())
  const dateDay = new Date(dateMinimum).getDate()
  const dateMonth = new Date(dateMinimum).getMonth()
  const [minimumPrice, setMinimumPrice] = useState(0)
  const [isLoading] = useState(false)

  const handleCheckSubmit = async () => {
    setLoading(true)
    await handlePayment(cart, () => navigate(`/ms-payment-info`))
  }

  // add menu in same time & date
  const addMenu = (date, time) => {
    setDatePick(date)
    sessionStorage.setItem('dateparams', JSON.stringify(dateGMT(date)))
    setDateParams(dateGMT(date))
    setTime(time)
    sessionStorage.setItem('date', JSON.stringify(date))
    sessionStorage.setItem('time', JSON.stringify(time))
    navigate(`/microsite?token=${token}`)
  }

  const [isOpen, setIsOpen] = useState(false)
  const [quantity, setQuantity] = useState(0)

  const dateIndex = new Date(datePick)
  let maxDate = new Date()
  maxDate.setMonth(maxDate.getMonth() + 3)
  let minDate = new Date()

  // penyesuaia libur lebaran 2025
  // jika mindate kurang dari = 2025-04-03 maka minDate akan di set ke 2025-04-03
  if (minDate <= new Date('2025-04-03')) {
    minDate = new Date('2025-04-03')
  }

  useEffect(() => {
    const now = new Date()
    if (
      now.getHours() > 15 ||
      (now.getHours() === 15 && now.getMinutes() >= 30)
    ) {
      minDate.setDate(minDate.getDate() + 1)
    }
  }, [])

  const handleSave = () => {
    sessionStorage.setItem('time', JSON.stringify(time))
    sessionStorage.setItem('date', JSON.stringify(datePick))

    sessionStorage.setItem('dateparams', JSON.stringify(dateGMT(dateIndex)))
    setDateParams(dateGMT(dateIndex))
    setIsOpen(false)
    navigate(`/microsite?token=${token}`)
  }

  useEffect(() => {
    let dataTmp = []
    for (let a = 0; a < cekData.length; a++) {
      const cek = cekData[a].findIndex(
        (x) => (x.is_main_menu == true && x.type == 2) || x.type == 1
      )
      if (cek == -1) {
        dataTmp.push({ data: true })
      }
    }
    const cekDataTmp = dataTmp.findIndex((a) => a.data == true)

    const dataLate = cart.find((data) => {
      const now = new Date()
      if (
        now.getHours() > 15 ||
        (now.getHours() === 15 && now.getMinutes() >= 30)
      ) {
        if (differentDate(data.date) < 2) return data
      } else {
        if (differentDate(data.date) < 1) return data
      }
    })

    if (dataLate) {
      setLateOrder(true)
      setDateLate(dataLate)
    } else {
      setLateOrder(false)
    }

    setTimeout(() => {
      checkNotAvail >= 0 && setAlertMenuNotAvail(true)
    }, 500)
    // checkTotalMinimum.length == 0 && setAlertMinOrder(false)
  }, [cart, checkNotAvail])

  return (
    <React.Fragment>
      <NavAddress>
        <div className=" -ml-12 md:hidden flex items-center gap-1">
          <h3 className="text-lg lg:text-xl font-semibold">
            Keranjang Pesanan
          </h3>
          <Tooltip>
            Kamu bisa pesan menu makan
            <br />
            lebih dari satu tanggal dan waktu
            <br /> dalam satu keranjang pesanan
          </Tooltip>
        </div>
      </NavAddress>
      <hr />
      {cart.length > 0 && (
        <div className="pb-32 ">
          <div className="p-4 max-w-sm mx-auto">
            {cart.map((data, key) => {
              // free ongkir hidden if any one is true
              let fulfilled = false
              const filterFreeOngkir = data.menus.filter(
                (menu) =>
                  menu.qty >= menu.free_shipping_cost &&
                  menu.free_shipping_cost !== 0
              )
              const flat = filterFreeOngkir.flatMap((data) => data)
              if (flat.length > 0) {
                fulfilled = true
              } else {
                fulfilled = false
              }
              return (
                <section key={key}>
                  <DateCart
                    cart={cart}
                    date={data.date}
                    time={data.shipping_time}
                    shipping={data.shipping_cost}
                    removeByDate={removeByDate}
                    changeDateCart={changeDateCart}
                    changeTimeCart={changeTimeCart}
                  />
                  {data.menus.map((menu, key) => (
                    <MenuCart
                      key={key}
                      name={menu.name}
                      qty={menu.qty}
                      available={menu.available}
                      price={menu.price}
                      unit={menu.qty_unit_name}
                      min_order={menu.min_order}
                      min_free={menu.free_shipping_cost}
                      disabled_up={menu.disable_up}
                      quantity={quantity}
                      setQuantity={setQuantity}
                      maxOrder={menu.qty < menu.max_order ? true : false}
                      addProduct={() =>
                        addProduct(menu, data.date, data.shipping_time)
                      }
                      updateQuantity={() =>
                        updateQuantity(
                          menu,
                          data.date,
                          data.shipping_time,
                          quantity
                        )
                      }
                      removeProduct={() =>
                        removeProduct(menu, data.date, data.shipping_time)
                      }
                      fulfilled={fulfilled}
                    />
                  ))}
                  {new Date(datePick).getDate() ==
                    new Date(data.date).getDate() &&
                  new Date(datePick).getMonth() ==
                    new Date(data.date).getMonth() &&
                  time == data.shipping_time ? null : (
                    <div className="border-t my-4 flex justify-between items-center w-full">
                      <button
                        onClick={() => addMenu(data.date, data.shipping_time)}
                        className="flex gap-1 items-center  py-3 text-primary font-semibold text-[10px]"
                      >
                        <AiOutlinePlus size={12} /> Tambah Menu
                      </button>
                    </div>
                  )}
                  <div className="h-3 mb-5 bg-primary opacity-5"></div>
                </section>
              )
            })}
            <button
              onClick={() => setIsOpen(true)}
              className="flex w-full border-2 py-1 justify-center text-primary rounded-full border-primary font-semibold text-[10px]"
            >
              Tambah Tanggal Pengiriman
            </button>
          </div>
          <div className="fixed bg-white flex flex-col w-full bottom-0">
            <div className="pt-3 px-4 flex flex-col justify-center mx-auto max-w-sm gap-1 w-full shadow-top-only bg-white">
              <LoadingButton
                disabled={lateOrder}
                onClick={() => handleCheckSubmit()}
                isLoading={isLoading}
                className="py-3 w-full rounded-full z-10 disabled:bg-gray-200 disabled:text-gray-400 text-white hover:opacity-80 bg-primary text-sm font-semibold flex justify-between px-5"
              >
                <p>Pembayaran</p>
                <p>{currency(grandTotal)}</p>
              </LoadingButton>
              <div className="flex justify-center h-5 mb-1 w-full">
                {errorMessage && (
                  <p className="text-[10px] text-center text-red-500">
                    {errorMessage}
                  </p>
                )}
                {alertMenuNotAvail && (
                  <p className="text-[11px] text-center text-primary">
                    Terdapat menu yang tak tersedia, harap cek menu
                  </p>
                )}
                {lateOrder && (
                  <p className="text-[10px] tracking-tight text-center text-primary">
                    Pesanan {new Date(dateLate.date).getDate()}{' '}
                    {MONTH_NAMES[new Date(dateLate.date).getMonth()].slice(
                      0,
                      3
                    )}{' '}
                    {new Date(dateLate.date).getFullYear()} sudah tidak berlaku,
                    harap ganti tanggal
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {cart.length === 0 && (
        <div className="flex h-full items-center justify-center">
          <EmptyCart />
        </div>
      )}

      {/* Popup DatePicker */}
      <DateTimePicker
        show={isOpen}
        setShow={setIsOpen}
        dateTmp={dateIndex}
        minDate={minDate}
        maxDate={maxDate}
        setDateTmp={setDatePick}
        time={time}
        setTime={setTime}
        setSave={handleSave}
      />
    </React.Fragment>
  )
}

export default CartMobileWA

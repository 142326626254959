import { useState, useCallback } from 'react'

const CUSTOMER_STORAGE_KEY = 'wa-customer'
const ADDRESS_STORAGE_KEY = 'dataAddress'

export const useCustomerStorage = () => {
  const [customerData, setCustomerData] = useState(() => {
    const savedCustomer = localStorage.getItem(CUSTOMER_STORAGE_KEY)
    return savedCustomer
      ? JSON.parse(savedCustomer)
      : {
          name: '',
          phone: '',
        }
  })

  const saveCustomerData = useCallback((data) => {
    const customerInfo = {
      name: data.name,
      phone: data.phone,
    }
    localStorage.setItem(CUSTOMER_STORAGE_KEY, JSON.stringify(customerInfo))
    setCustomerData(customerInfo)
  }, [])

  const getSavedAddress = useCallback(() => {
    const savedAddress = localStorage.getItem(ADDRESS_STORAGE_KEY)
    if (!savedAddress) return null

    try {
      return JSON.parse(savedAddress)
    } catch (error) {
      console.error('Error parsing saved address:', error)
      return null
    }
  }, [])

  const saveAddressData = useCallback((addressData) => {
    try {
      localStorage.setItem(ADDRESS_STORAGE_KEY, JSON.stringify(addressData))
    } catch (error) {
      console.error('Error saving address data:', error)
    }
  }, [])

  const clearStoredData = useCallback(() => {
    localStorage.removeItem(CUSTOMER_STORAGE_KEY)
    localStorage.removeItem(ADDRESS_STORAGE_KEY)
    setCustomerData({
      name: '',
      phone: '',
    })
  }, [])

  return {
    customerData,
    saveCustomerData,
    getSavedAddress,
    saveAddressData,
    clearStoredData,
  }
}

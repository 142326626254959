import clockIcon from 'images/mitra/clock-icon.svg'
import dateIcon from 'images/mitra/date-icon.svg'
import React, { useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { dateGMT, MONTH_NAMES } from 'utils/date'

import DateTimePicker from './DateTimePicker'

const DateTime = ({
  defaultDate,
  date,
  setDate,
  setDateParams,
  time,
  setTime,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [dateIndex, setDateIndex] = useState(new Date(defaultDate))

  let maxDate = new Date()
  maxDate.setMonth(maxDate.getMonth() + 3)

  let minDate = new Date()
  // penyesuaia libur lebaran 2025
  // jika mindate kurang dari = 2025-04-03 maka minDate akan di set ke 2025-04-03
  if (minDate <= new Date('2025-04-02')) {
    minDate = new Date('2025-04-03')
  }

  const now = new Date()
  if (
    now.getHours() > 15 ||
    (now.getHours() === 15 && now.getMinutes() >= 30)
  ) {
    minDate.setDate(minDate.getDate() + 1)
  }

  const handleSave = () => {
    sessionStorage.setItem('time', JSON.stringify(time))
    sessionStorage.setItem('date', JSON.stringify(dateIndex))
    sessionStorage.setItem('dateparams', JSON.stringify(dateGMT(dateIndex)))
    setDate(dateIndex)
    setDateParams(dateGMT(dateIndex))
    setIsOpen(false)
  }

  return (
    <div className="flex px-4 md:px-0 items-center border-b md:border-b-2 py-5 gap-2 flex-row justify-between">
      <h3 className="font-semibold flex gap-1 text-sm tracking-tight md:tracking-normal md:text-xl">
        <span className="hidden md:block">Silahkan</span> Pilih Menu
      </h3>
      <div className="flex gap-2 text-primary">
        <div
          onClick={() => setIsOpen(true)}
          className="rounded-lg md:border flex gap-0.5 md:gap-2 items-center md:px-3 md:py-2"
        >
          <img src={dateIcon} alt="date" className="w-4 md:w-5 h-4 md:h-5" />
          <div className="font-semibold cursor-pointer text-sm tracking-tight md:tracking-normal md:text-lg">
            {new Date(date || defaultDate)?.getDate()}{' '}
            {MONTH_NAMES[new Date(date || defaultDate)?.getMonth()]}{' '}
            {new Date(date || defaultDate)?.getFullYear()}
          </div>
          <MdKeyboardArrowDown />
        </div>
        <div
          onClick={() => setIsOpen(true)}
          className="rounded-lg md:border flex gap-0.5 md:gap-2 items-center md:px-3 md:py-2"
        >
          <img src={clockIcon} alt="clock" className="w-4 md:w-5 h-4 md:h-5" />
          <div className="font-semibold cursor-pointer tracking-tight md:tracking-normal text-sm md:text-lg">
            {time.replaceAll('.', ':')}
          </div>
          <MdKeyboardArrowDown />
        </div>
      </div>

      {/* Popup DatePicker */}
      <DateTimePicker
        show={isOpen}
        setShow={setIsOpen}
        dateTmp={dateIndex}
        minDate={minDate}
        maxDate={maxDate}
        setDateTmp={setDateIndex}
        time={time}
        setTime={setTime}
        setSave={handleSave}
      />
    </div>
  )
}

export default DateTime

// eslint-disable-next-line no-unused-vars
import { filterNested } from 'array-nested-filter'
import {
  usePartnerListMutation,
  usePartnerShippingCostMutation,
} from 'modules/Microsite/hook'
import { fetchMenuExist } from 'modules/Mitra/api'
import { useEffect, useState } from 'react'
import { useImmer } from 'use-immer'

export const useMicrositeCartSystem = () => {
  const [loadingCart, setLoadingCart] = useState(false)
  const [loading, setLoading] = useState(false)
  const [cart, setCart] = useImmer(
    JSON.parse(localStorage.getItem('cart')) || []
  )
  const [time, setTime] = useState(
    JSON.parse(sessionStorage.getItem('time')) || '10.00'
  )

  const [errorMessage, setErrorMessage] = useState()

  let defaultParam = new Date()
  const now = new Date()
  if (
    now.getHours() > 15 ||
    (now.getHours() === 15 && now.getMinutes() >= 30)
  ) {
    defaultParam.setDate(defaultParam.getDate() + 2)
  } else {
    defaultParam.setDate(defaultParam.getDate() + 1)
  }
  const [dateParams, setDateParams] = useState(
    JSON.parse(sessionStorage.getItem('dateparams')) || defaultParam
  )

  let defaultDate = new Date().toDateString()
  defaultDate = new Date(defaultDate)
  if (
    now.getHours() > 15 ||
    (now.getHours() === 15 && now.getMinutes() >= 30)
  ) {
    defaultDate.setDate(defaultDate.getDate() + 2)
  } else {
    defaultDate.setDate(defaultDate.getDate() + 1)
  }

  // penyesuaia libur lebaran 2025
  // jika mindate kurang dari = 2025-04-03 maka minDate akan di set ke 2025-04-03
  if (defaultDate <= new Date('2025-04-03')) {
    defaultDate = new Date('2025-04-04')
  }

  defaultDate = Date.parse(defaultDate)
  const [datePick, setDatePick] = useState(
    JSON.parse(sessionStorage.getItem('date')) || defaultDate
  )
  const dateConvert = new Date(datePick).toDateString()
  const dateTime = Date.parse(dateConvert)

  const [grandTotal, setGrandTotal] = useState(
    JSON.parse(localStorage.getItem('price')) || 0
  )

  const [dateChange, setDateChange] = useState(0)
  const [dateCheck, setDateCheck] = useState([])

  useEffect(() => {
    sessionStorage.setItem('time', JSON.stringify('10.00'))
  }, [])

  const { mutate: fetchPartnerList } = usePartnerListMutation()
  const { mutate: fetchShippingCost } = usePartnerShippingCostMutation()

  // calculate grandtotal
  const calculateTotal = (cart) => {
    const subtotal = cart.map((date) =>
      date.menus.map((menu) => menu.sub_total)
    )
    const subtotalflat = subtotal.flatMap((item) => item)
    const total = subtotalflat.reduce((total, b) => total + b, 0)

    const ongkir = JSON.parse(localStorage.getItem('ongkir'))
    if (ongkir > 0) {
      setGrandTotal(total + ongkir)
    }
    // else {
    //   setGrandTotal(total)
    // }

    localStorage.setItem('price', total)
  }

  // subtotal per date
  const sumPerDate = (cart) => {
    const subtotal = cart.menus.map((menu) => menu.sub_total)
    const total = subtotal.reduce((total, a) => total + a, 0)
    return total
  }

  // qty per date
  const qtyPerDate = (cart) => {
    const subtotal = cart.menus.map((menu) => menu.qty)
    const total = subtotal.reduce((total, a) => total + a, 0)
    return total
  }

  // add product
  const addProduct = (product, dateMenu, timeMenu) => {
    setCart((draftCart) => {
      const dateIndex = cart.findIndex(
        (data) => data.date === dateMenu && data.shipping_time === timeMenu
      )
      if (dateIndex !== -1) {
        const findMenu = draftCart[dateIndex]?.menus.findIndex(
          (item) => item.name === product.name
        )
        if (findMenu >= 0) {
          draftCart[dateIndex].menus.map((item) => {
            if (item.name === product.name) {
              item.qty += 1
            }
            item.sub_total = item.qty * item.price
            // setTimeout(() => {
            //   location.reload(true)
            // }, 2000)
          })
        } else {
          // append menu
          const newMenu = {
            available: true,
            name: product.name,
            price: product.price,
            min_order: product.min_order,
            qty: product.min_order == 0 ? 1 : product.min_order,
            qty_unit_name: product.qty_unit_name,
            qty_unit_id: product.qty_unit_id,
            sub_total:
              product.min_order !== 0
                ? product.min_order * product.price
                : product.price * 1,
          }
          draftCart[dateIndex].menus.push(newMenu)
        }
        draftCart[dateIndex].sub_total = sumPerDate(draftCart[dateIndex])
        draftCart[dateIndex].qty_total = qtyPerDate(draftCart[dateIndex])
        calculateTotal(draftCart)
        localStorage.setItem('cart', JSON.stringify(draftCart))
      } else {
        // add cart
        const newDate = {
          date: dateMenu,
          sub_total:
            product.min_order !== 0
              ? product.min_order * product.price
              : product.price * 1,
          qty_total: product.min_order == 0 ? 1 : product.min_order,
          shipping_time: timeMenu,
          menus: [
            {
              available: true,
              name: product.name,
              price: product.price,
              min_order: product.min_order,
              qty: product.min_order == 0 ? 1 : product.min_order,
              qty_unit_name: product.qty_unit_name,
              qty_unit_id: product.qty_unit_id,
              sub_total:
                product.min_order !== 0
                  ? product.min_order * product.price
                  : product.price * 1,
            },
          ],
        }
        draftCart.push(newDate)
        calculateTotal(draftCart)
        localStorage.setItem('cart', JSON.stringify(draftCart))
      }
    })
  }

  // add manual quantity
  const updateQuantity = (product, dateMenu, timeMenu, value) => {
    setCart((draftCart) => {
      const dateIndex = cart.findIndex(
        (data) => data.date === dateMenu && data.shipping_time === timeMenu
      )

      if (dateIndex !== -1) {
        const findMenu = draftCart[dateIndex]?.menus.findIndex(
          (item) => item.name === product.name
        )
        if (findMenu !== -1) {
          draftCart[dateIndex].menus.map((item) => {
            if (item.name === product.name) {
              if (
                value >= product.min_order &&
                value != 0 &&
                value.length > 0
              ) {
                item.qty = Number(value)
              } else {
                item.qty
              }
            }
            item.sub_total = item.qty * item.price
          })
        }
      }
      draftCart[dateIndex].sub_total = sumPerDate(draftCart[dateIndex])
      draftCart[dateIndex].qty_total = qtyPerDate(draftCart[dateIndex])
      calculateTotal(draftCart)
      localStorage.setItem('cart', JSON.stringify(draftCart))
    })
  }

  // delete product
  const removeProduct = (product, dateMenu, timeMenu) => {
    setDateCheck([])

    setCart((draftCart) => {
      const dateIndex = cart.findIndex(
        (data) => data.date === dateMenu && data.shipping_time === timeMenu
      )
      if (dateIndex !== -1) {
        const findMenu = draftCart[dateIndex].menus.findIndex(
          (item) => item.name === product.name
        )
        if (findMenu !== -1) {
          draftCart[dateIndex].menus.map((item) => {
            if (item.name === product.name) {
              if (item.qty > product.min_order && item.qty > 1) {
                item.qty -= 1
              } else {
                if (cart[dateIndex].menus.length === 1) {
                  draftCart.splice(dateIndex, 1)
                } else {
                  draftCart[dateIndex].menus.splice(findMenu, 1)
                }
              }
              item.sub_total = item.qty * item.price
            }
          })
          if (draftCart.length > 0) {
            if (draftCart[dateIndex]) {
              draftCart[dateIndex].sub_total = sumPerDate(draftCart[dateIndex])
              draftCart[dateIndex].qty_total = qtyPerDate(draftCart[dateIndex])
            }
          }
        }
      }
      calculateTotal(draftCart)
      localStorage.setItem('cart', JSON.stringify(draftCart))
    })
  }

  const removeByDate = (date, time) => {
    setDateCheck([])
    setCart((draftCart) => {
      const dateIndex = draftCart.findIndex(
        (data) => data.date === date && data.shipping_time === time
      )
      draftCart.splice(dateIndex, 1)
      calculateTotal(draftCart)
      localStorage.setItem('cart', JSON.stringify(draftCart))
    })
  }

  const changeDateCart = (date, time, value) => {
    setLoadingCart(true)
    if (date === value) return setLoadingCart(false)
    const dateGMT = new Date(value).setHours(new Date(value).getHours() + 7)
    setCart((draftCart) => {
      const dateIndex = draftCart.findIndex(
        (data) => data.date === date && data.shipping_time === time
      )
      const dateValue = draftCart.findIndex(
        (data) => data.date === value && data.shipping_time === time
      )
      draftCart[dateIndex].date = value

      let item = []
      for (let i = 0; i < draftCart[dateIndex].menus.length; i++) {
        fetchMenuExist(draftCart[dateIndex].menus[i].name, dateGMT / 1000).then(
          (result) => {
            item.push({
              index: cart[dateIndex].menus[i].name,
              avail: result.result.data,
            })
          }
        )
      }
      setDateCheck(item)

      if (dateValue !== -1) {
        let newMenu = cart[dateIndex].menus
        for (let i = 0; i < cart[dateValue].menus.length; i++) {
          for (let a = 0; a < newMenu.length; a++) {
            if (cart[dateValue].menus[i].name === newMenu[a].name) {
              {
                draftCart[dateValue].menus[i].qty += newMenu[a].qty
              }
              const deleteMenu = newMenu[a]
              newMenu = newMenu.filter((item) => item.name !== deleteMenu.name)
            }
          }
        }
        draftCart[dateValue].menus.push(...newMenu)
        draftCart.splice(dateIndex, 1)
      }
      setDateChange(value)
      localStorage.setItem('cart', JSON.stringify(draftCart))
    })
    setTimeout(() => {
      setLoadingCart(false)
      // location.reload(true)
    }, 500)
  }

  const changeTimeCart = (date, time, value) => {
    setCart((draftCart) => {
      const dateIndex = draftCart.findIndex(
        (data) => data.date === date && data.shipping_time === time
      )
      draftCart[dateIndex].shipping_time = value
      localStorage.setItem('cart', JSON.stringify(draftCart))
    })

    // location.reload(true)
  }

  const handlePayment = (cart, onSuccessHandlePayment) => {
    setLoading(true)
    const menuNames = [
      ...new Set(
        cart.flatMap((order) => order.menus?.map((menu) => menu.name) || [])
      ),
    ]

    const payload = {
      menu_names: menuNames,
      lat: +JSON.parse(localStorage.getItem('data-user')).lat,
      lon: +JSON.parse(localStorage.getItem('data-user')).long,
    }

    fetchPartnerList(payload, {
      onSuccess: (data) => {
        if (data.status.status_code !== 20) {
          setLoading(false)
          return alert(data.status.message_client)
        }

        localStorage.setItem('all-partners', JSON.stringify(data.result))

        const menuQuantities = {}
        cart.forEach((order) => {
          order.menus?.forEach((menu) => {
            menuQuantities[menu.name] = menu.qty
          })
        })

        const filteredPartners = data.result.filter((partner) => {
          return partner.menus.every((partnerMenu) => {
            const cartQty = menuQuantities[partnerMenu.menu_name] || 0
            return cartQty >= partnerMenu.min_order
          })
        })

        console.log('Original partners:', data.result)
        console.log('Filtered partners:', filteredPartners)

        if (filteredPartners.length > 0) {
          localStorage.setItem('partner-list', JSON.stringify(filteredPartners))
          localStorage.setItem('partner', JSON.stringify(filteredPartners[0]))
          getPartnerShippingCost(cart, onSuccessHandlePayment)
        } else {
          setErrorMessage('Tidak ada partner yang bisa memenuhi pesanan Anda')
          setLoading(false)
          return errorMessage
        }
      },
      onError: (error) => {
        console.error(error)
        setLoading(false)
      },
    })
  }

  const updatePartnerList = (cart) => {
    const allPartners = JSON.parse(localStorage.getItem('all-partners') || '[]')

    const menuQuantities = {}
    cart.forEach((order) => {
      order.menus?.forEach((menu) => {
        menuQuantities[menu.name] = menu.qty
      })
    })

    const filteredPartners = allPartners.filter((partner) => {
      return partner.menus.every((partnerMenu) => {
        const cartQty = menuQuantities[partnerMenu.menu_name] || 0
        return cartQty >= partnerMenu.min_order
      })
    })

    localStorage.setItem('partner-list', JSON.stringify(filteredPartners))

    if (filteredPartners.length > 0) {
      const currentPartner = JSON.parse(localStorage.getItem('partner') || '{}')
      const isCurrentPartnerValid = filteredPartners.some(
        (p) => p.partner_id === currentPartner.partner_id
      )
      if (!isCurrentPartnerValid) {
        localStorage.setItem('partner', JSON.stringify(filteredPartners[0]))
      }
    }

    return filteredPartners
  }

  const getPartnerShippingCost = (cart, onSuccessHandlePayment) => {
    setLoading(true)

    console.log(cart, 'test')

    let highestCost = []

    const menuNames = [
      ...new Set(
        cart.flatMap((order) => order.menus?.map((menu) => menu.name) || [])
      ),
    ]

    const payload = {
      lat: +JSON.parse(localStorage.getItem('data-user')).lat,
      lon: +JSON.parse(localStorage.getItem('data-user')).long,
      partner_id: JSON.parse(localStorage.getItem('partner')).partner_id,
      menu_names: menuNames,
    }

    fetchShippingCost(payload, {
      onSuccess: (data) => {
        if (data.status.status_code !== 20) {
          setLoading(false)
          return alert(data.status.message_client)
        }

        if (data.result) {
          const partner = data.result
          localStorage.setItem('partner', JSON.stringify(partner))

          // const invalidMenus = []

          // cart.forEach((cartItem) => {
          //   cartItem.menus.forEach((menu) => {
          //     const isValid = partner.menu?.some(
          //       (ptn) => ptn.title === menu.name
          //     )
          //     if (!isValid) {
          //       invalidMenus.push(menu.name)
          //     }
          //   })
          // })

          // if (invalidMenus.length > 0) {
          //   setErrorMessage('Beberapa menu tidak bisa dipesan berbarengan')

          //   return errorMessage
          // }

          const newCart = cart.map((cartItem, i) => {
            highestCost.push(0)
            let matchingCost
            const newCartItemMenu = cartItem.menus.map((menu) => {
              const partnerMenu = partner.menu?.find(
                (ptn) => ptn.title === menu.name
              )

              if (partnerMenu) {
                matchingCost = partnerMenu.shipping_cost_detail?.find(
                  (cost) =>
                    menu.qty >= cost.qty_fr &&
                    (cost.qty_to === null || menu.qty <= cost.qty_to)
                )

                if (matchingCost) {
                  highestCost[i] = Math.max(highestCost[i], matchingCost.cost)
                }
                const temp = {
                  menu_id: partnerMenu.menu_id,
                  ...menu,
                }
                return temp
              }
            })

            return {
              ...cartItem,
              menus: newCartItemMenu,
              shipping_cost:
                cartItem.menus.length > 1 ? highestCost[i] : matchingCost.cost,
            }
          })
          localStorage.setItem(
            'ongkir',
            JSON.stringify(highestCost.reduce((a, b) => a + b, 0))
          )

          const filteredCart = filterNested({
            array: newCart,
            search: true,
            searchAttribute: 'available',
            childrenPath: 'menus',
          })
          localStorage.setItem('cart', JSON.stringify(filteredCart))

          onSuccessHandlePayment()
          setLoading(false)
        }
      },
      onError: (error) => {
        console.error(error)
        setLoading(false)
      },
    })
  }

  useEffect(() => {
    setTimeout(() => {
      if (dateCheck.length > 0) {
        setCart((draftCart) => {
          let dateChanges = cart.findIndex((data) => data.date === dateChange)
          for (let i = 0; i < draftCart[dateChanges].menus.length; i++) {
            for (let a = 0; a < dateCheck.length; a++) {
              if (dateCheck[a].name === draftCart[dateChanges].menus[i].name) {
                draftCart[dateChanges].menus[i].available = dateCheck[a].avail
              }
            }
          }
          localStorage.setItem('cart', JSON.stringify(draftCart))
        })
      }
    }, 800)

    setTimeout(() => {
      setDateCheck([])
    }, 800)
  }, [cart, dateChange])

  return {
    loading,
    setLoading,
    dateParams,
    setDateParams,
    cart,
    setCart,
    datePick,
    setDatePick,
    dateTime,
    time,
    setTime,
    updatePartnerList,
    addProduct,
    updateQuantity,
    removeProduct,
    removeByDate,
    errorMessage,
    handlePayment,
    grandTotal,
    changeDateCart,
    changeTimeCart,
    calculateTotal,
    loadingCart,
    getPartnerShippingCost,
  }
}

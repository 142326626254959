import { yupResolver } from '@hookform/resolvers/yup'
import { useJsApiLoader } from '@react-google-maps/api'
import LoadingButton from 'components/button/LoadingButton'
import LocationIcon from 'images/location.svg'
import Form from 'components/forms/Form'
import Input from 'components/forms/Input'
import { useGoogleMapGeocode } from 'modules/custom/useGoogleMapGeocode'
import { useGoogleMapHook } from 'modules/custom/useGoogleMapHook'
import { createMicrositeLink } from 'modules/Microsite/api'
import React, { useEffect, useState, useRef, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import GoogleMapsComponent from './components/GoogleMapsComponent'
import ContactFormModal from './components/ContactFormModal'
import { DEFAULT_CENTER, LIBRARIES } from './constants'
import { useLocationValidation } from 'modules/custom/useLocationValidation'
import { useCustomerStorage } from 'modules/custom/useCustomerStorage'
import { useLoadingError } from 'modules/custom/useLoadingError'
import { useMapsInteraction } from 'modules/custom/useMapsInteraction'

const MicrositeOrder = () => {
  const [showContactModal, setShowContactModal] = useState(false)
  const [innerHeight, setinnerHeight] = useState(window.innerHeight)
  const [libraries] = useState(LIBRARIES)
  const [map, setMap] = useState(null)
  const [autoComplete, setAutoComplete] = useState(null)

  const autoCompleteRef = useRef()
  const nameInputRef = useRef(null)

  const {
    latLong,
    setLatLong,
    place,
    setPlace,
    setURL,
    setRegion,
    isPartnerExist,
    setIsPartnerExist,
  } = useGoogleMapHook()

  const { validateLocation } = useLocationValidation()
  const {
    customerData: contactForm,
    saveCustomerData: setContactForm,
    getSavedAddress,
  } = useCustomerStorage()
  const { isLoading, error, wrapPromise, setErrorMessage } = useLoadingError()
  const {
    loadingPlace,
    handlePlaceSelect,
    handleMapDrag,
    handleMapDragEnd,
    // resetMap
  } = useMapsInteraction(
    map,
    setLatLong,
    setPlace,
    setURL,
    setRegion,
    setIsPartnerExist
  )

  const schema = yup.object().shape({
    detailLocation: yup.string().required('Wajib diisi'),
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      detailLocation: '',
    },
  })

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_API_KEY_GMAPS,
    libraries: libraries,
  })

  const { getAddress } = useGoogleMapGeocode()
  const center = useMemo(() => DEFAULT_CENTER, [])
  const dataAddress = getSavedAddress()

  const onLoad = async (map) => {
    if (dataAddress) {
      setValue('detailLocation', dataAddress.detailLocation)
    }

    const elementMap = document.getElementById('set-address-map')
    if (!document.querySelector('.centerMarker')) {
      const div = document.createElement('div')
      div.classList.add('centerMarker')
      elementMap.appendChild(div)
    }

    const initialLocation = dataAddress || center
    const res = await getAddress(`
      ${initialLocation.lat},
      ${initialLocation.lng}
    `)

    if (dataAddress?.formattedAddress) {
      setPlace?.(dataAddress.formattedAddress)
    } else {
      setPlace?.(res.formatted_address)
    }

    map.fitBounds(
      new window.google.maps.LatLngBounds({
        lat: initialLocation.lat,
        lng: initialLocation.lng,
      })
    )

    setMap(map)
    setLatLong?.(initialLocation)
  }

  const onUnmount = () => {
    setMap(null)
    setLatLong?.(null)
    setPlace?.(null)
    setAutoComplete(null)
    setValue('detailLocation', '')
  }

  const onSubmit = async (formData) => {
    try {
      const locationValidation = validateLocation(latLong)
      if (!locationValidation.isValid) {
        setErrorMessage(locationValidation.error)
        return
      }

      const payload = {
        room_wa_id: '0',
        name: formData.name,
        phone: formData.phone,
        address: place,
        lat: latLong.lat,
        long: latLong.lng,
      }

      setContactForm({
        name: formData.name,
        phone: formData.phone,
      })

      const response = await wrapPromise(createMicrositeLink(payload))

      if (response?.result?.microsite_link) {
        window.location.href = response.result.microsite_link
      }

      setShowContactModal(false)
    } catch (err) {
      // Error already handled by wrapPromise
    }
  }

  useEffect(() => {
    setinnerHeight(window.innerHeight)
  }, [])

  useEffect(() => {
    if (showContactModal && nameInputRef.current) {
      nameInputRef.current.focus()
    }
  }, [showContactModal])

  return (
    <React.Fragment>
      <div className="container">
        <div className="w-full p-0 py-5 bg-white flex items-center">
          <div className="mx-auto flex w-full justify-start md:justify-between items-center font-medium">
            <div className="w-auto h-auto relative">
              <img src="/images/kendixpress.svg" className="w-44" alt="Logo" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex container py-4 md:py-5 md:pb-10 flex-col gap-2">
        <h2 className={`font-semibold md:text-[26px]`}>Mau dikirim kemana?</h2>
        <p className="text-sm">
          Sesuaikan pin lokasi dengan lokasi pengirimanmu
        </p>

        <div
          className="w-full h-full md:h-auto md:!min-h-[auto] flex flex-col"
          style={{ minHeight: `calc(${innerHeight}px - 68px)` }}
        >
          <div>
            <GoogleMapsComponent
              isLoaded={isLoaded}
              onLoad={onLoad}
              onUnmount={onUnmount}
              onLoadAutoComplete={setAutoComplete}
              onPlaceChange={() => handlePlaceSelect(autoComplete?.getPlace())}
              blurMapOnDrag={handleMapDrag}
              getLatLongCenter={handleMapDragEnd}
              autoCompleteRef={autoCompleteRef}
            />
          </div>
          {isPartnerExist && (
            <div className="px-4 py-5 border-b border-l border-r border-[#EFEFEF] md:rounded-b-[20px] flex items-baseline flex-wrap">
              {loadingPlace && (
                <div role="status" className="space-y-2.5 animate-pulse w-full">
                  <div className="flex items-center space-x-2 w-full">
                    <div className="h-2.5 bg-gray-200 rounded-full w-32"></div>
                    <div className="h-2.5 bg-gray-300 rounded-full w-24"></div>
                    <div className="h-2.5 bg-gray-300 rounded-full w-full"></div>
                  </div>
                  <div className="flex items-center w-full space-x-2 max-w-[480px]">
                    <div className="h-2.5 bg-gray-200 rounded-full w-full"></div>
                    <div className="h-2.5 bg-gray-300 rounded-full w-full"></div>
                    <div className="h-2.5 bg-gray-300 rounded-full w-24"></div>
                  </div>
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              {!loadingPlace && (
                <>
                  <div className="w-2/5 md:w-1/5 text-sm font-semibold">
                    Dikirim ke
                  </div>
                  <div className="w-full md:w-4/5 text-sm">{place || '-'}</div>
                </>
              )}
            </div>
          )}
          {!isPartnerExist && (
            <>
              <div className="bg-[#fff4f4] px-4 py-2 border-b border-l border-r border-[#EFEFEF] flex items-center text-primary">
                <div className="w-full text-xs">
                  <span className="font-semibold mr-2">Mohon Maaf</span>Lokasimu
                  diluar jangkauan Pengiriman Kami
                </div>
              </div>
              <div className="px-4 py-[15px] border-b border-l border-r border-[#EFEFEF] md:rounded-b-[20px]">
                <div className="w-full text-xs flex items-center">
                  <div className="text-black">
                    Kamu ingin pesan menu untuk lokasi ini?
                  </div>
                  <a
                    href="https://wa.me/6281119279900?text=Saya%20ingin%20pesan%20menu%20untuk%20lokasi%20di"
                    target="_blank"
                    className="ml-3 px-2 py-[6px] rounded-full text-center bg-primary border-2 border-primary text-white w-32 hover:bg-red-800 hover:border-red-800 text-xs"
                    rel="noreferrer"
                  >
                    Hubungi Kami
                  </a>
                </div>
              </div>
            </>
          )}
          {isPartnerExist && (
            <>
              <Form
                className="mt-9 mb-9 md:mb-0"
                autoComplete="off"
                onSubmit={(e) => {
                  e.preventDefault()
                }}
              >
                <div className="mx-3 md:mx-0 flex">
                  <div className="w-1/12">
                    <img
                      src={LocationIcon}
                      className="w-6 h-6 mx-auto"
                      alt="Location Icon"
                    />
                  </div>
                  <div className="w-11/12">
                    <Controller
                      name="detailLocation"
                      control={control}
                      defaultValue={''}
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="text"
                          label="Detail Lokasi (Cth: Patokan)"
                          name="detailLocation"
                          onKeyUp={(event) => {
                            if (event.keyCode === 13) {
                              handleSubmit(() => {
                                setShowContactModal(true)
                              })(event)
                            }
                          }}
                          error={errors.detailLocation?.message}
                        />
                      )}
                    />
                  </div>
                </div>
              </Form>
              <div className="mx-3 md:mx-0 md:mt-9 mb-3 md:mb-0 flex justify-end relative w-[stretch] md:w-auto">
                <LoadingButton
                  className="w-1/2 md:w-[128px]"
                  variant="primary"
                  isRounded={true}
                  isLoading={loadingPlace}
                  onClick={handleSubmit(() => {
                    setShowContactModal(true)
                  })}
                >
                  Lanjut
                </LoadingButton>
              </div>
            </>
          )}
        </div>
      </div>

      <ContactFormModal
        showModal={showContactModal}
        onClose={() => setShowContactModal(false)}
        contactForm={contactForm}
        setContactForm={setContactForm}
        onSubmit={onSubmit}
        nameInputRef={nameInputRef}
        isLoading={isLoading}
        error={error}
      />
    </React.Fragment>
  )
}

export default MicrositeOrder

export const DEFAULT_CENTER = {
  lat: -6.2, // Jakarta's latitude
  lng: 106.816666, // Jakarta's longitude
}

export const INDONESIA_BOUNDS = {
  south: -11.0,
  west: 95.0,
  north: 6.0,
  east: 141.0,
}

export const DEFAULT_MAP_OPTIONS = {
  fullscreenControl: false,
  disableDefaultUI: true,
  maxZoom: 15,
  minZoom: 15,
  gestureHandling: 'greedy',
}

export const LIBRARIES = ['places']

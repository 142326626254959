import { INDONESIA_BOUNDS } from 'pages/MicrositeOrder/constants'

export const useLocationValidation = () => {
  const isWithinIndonesia = (lat, lng) => {
    return (
      lat >= INDONESIA_BOUNDS.south &&
      lat <= INDONESIA_BOUNDS.north &&
      lng >= INDONESIA_BOUNDS.west &&
      lng <= INDONESIA_BOUNDS.east
    )
  }

  const validateLocation = (location) => {
    if (!location?.lat || !location?.lng) {
      return {
        isValid: false,
        error: 'Koordinat lokasi tidak valid',
      }
    }

    if (!isWithinIndonesia(location.lat, location.lng)) {
      return {
        isValid: false,
        error: 'Lokasi harus berada di wilayah Indonesia',
      }
    }

    return {
      isValid: true,
      error: null,
    }
  }

  return {
    isWithinIndonesia,
    validateLocation,
  }
}

import { useCallback, useState } from 'react'
import { fetchNearMitra } from 'modules/Mitra/api'
import { DEFAULT_CENTER } from 'pages/MicrositeOrder/constants'

export const useMapsInteraction = (
  map,
  setLatLong,
  setPlace,
  setURL,
  setRegion,
  setIsPartnerExist
) => {
  const [loadingPlace, setLoadingPlace] = useState(false)

  const handlePlaceSelect = useCallback(
    async (place) => {
      if (!place.geometry) return

      const location = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }

      setLatLong?.(location)

      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport)
      } else {
        map.setCenter(place.geometry.location)
        map.setZoom(15)
      }

      setLoadingPlace(true)
      setPlace?.(place.formatted_address)
      setURL?.(place.url)

      // Extract region from address components
      place.address_components.forEach((data) => {
        if (data.types[0] === 'administrative_area_level_2') {
          setRegion?.(data.long_name)
        }
      })

      try {
        const nearMitraResponse = await fetchNearMitra(
          location.lat,
          location.lng
        )
        setIsPartnerExist?.(nearMitraResponse.result.length > 0)
      } catch (error) {
        console.error('Error fetching near mitra:', error)
        setIsPartnerExist?.(false)
      }

      setLoadingPlace(false)
    },
    [map, setLatLong, setPlace, setURL, setRegion, setIsPartnerExist]
  )

  const handleMapDrag = useCallback(() => {
    const mapElem = document.querySelector('#set-address-map div')
    mapElem?.classList.add('opacity-20')
  }, [])

  const handleMapDragEnd = useCallback(async () => {
    const mapElem = document.querySelector('#set-address-map div')
    mapElem?.classList.remove('opacity-20')

    if (!map) return

    const center = map.getCenter()
    const lat = center.lat()
    const lng = center.lng()

    const location = { lat, lng }
    setLatLong?.(location)
    setLoadingPlace(true)

    try {
      const service = new window.google.maps.places.PlacesService(map)

      const request = {
        location: location,
        radius: '100',
      }

      service.nearbySearch(request, async (results, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          results[0]
        ) {
          const placeRequest = {
            placeId: results[0].place_id,
            fields: ['address_components', 'formatted_address', 'url'],
          }

          service.getDetails(placeRequest, (place, detailStatus) => {
            if (
              detailStatus === window.google.maps.places.PlacesServiceStatus.OK
            ) {
              setPlace?.(place.formatted_address)
              setURL?.(place.url)

              place.address_components.forEach((data) => {
                if (data.types[0] === 'administrative_area_level_2') {
                  setRegion?.(data.long_name)
                }
              })
            }
          })
        }
      })

      const nearMitraResponse = await fetchNearMitra(lat, lng)
      setIsPartnerExist?.(nearMitraResponse.result.length > 0)
    } catch (error) {
      console.error('Error updating location:', error)
      setIsPartnerExist?.(false)
    }

    setLoadingPlace(false)
  }, [map, setLatLong, setPlace, setURL, setRegion, setIsPartnerExist])

  const resetMap = useCallback(() => {
    if (map) {
      map.setCenter(DEFAULT_CENTER)
      map.setZoom(15)
      setLatLong?.(DEFAULT_CENTER)
      setPlace?.('')
      setURL?.('')
      setRegion?.('')
      setIsPartnerExist?.(false)
    }
  }, [map, setLatLong, setPlace, setURL, setRegion, setIsPartnerExist])

  return {
    loadingPlace,
    handlePlaceSelect,
    handleMapDrag,
    handleMapDragEnd,
    resetMap,
  }
}

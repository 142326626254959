import React from 'react'
import { GoogleMap, Autocomplete } from '@react-google-maps/api'
import { MdClose } from 'react-icons/md'
import { checkIsMobile } from 'utils/checkIsMobile'
import { INDONESIA_BOUNDS, DEFAULT_MAP_OPTIONS } from '../constants'

const GoogleMapsComponent = ({
  isLoaded,
  onLoad,
  onUnmount,
  onLoadAutoComplete,
  onPlaceChange,
  blurMapOnDrag,
  getLatLongCenter,
  autoCompleteRef,
}) => {
  if (!isLoaded) return null

  return (
    <GoogleMap
      id="set-address-map"
      mapContainerClassName="w-full md:w-full h-[334px] rounded-t-[20px] relative"
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onDragStart={blurMapOnDrag}
      onDragEnd={getLatLongCenter}
      options={{
        ...DEFAULT_MAP_OPTIONS,
        restriction: {
          latLngBounds: {
            north: INDONESIA_BOUNDS.north,
            south: INDONESIA_BOUNDS.south,
            east: INDONESIA_BOUNDS.east,
            west: INDONESIA_BOUNDS.west,
          },
          strictBounds: true,
        },
      }}
      clickableIcons={false}
    >
      <Autocomplete
        onLoad={onLoadAutoComplete}
        onPlaceChanged={onPlaceChange}
        className="absolute w-[stretch] top-0 left-0 mx-4 mt-4"
        options={{
          bounds: new window.google.maps.LatLngBounds(
            new window.google.maps.LatLng(
              INDONESIA_BOUNDS.south,
              INDONESIA_BOUNDS.west
            ),
            new window.google.maps.LatLng(
              INDONESIA_BOUNDS.north,
              INDONESIA_BOUNDS.east
            )
          ),
          componentRestrictions: { country: 'id' },
          strictBounds: true,
          types: ['geocode', 'establishment'],
          fields: [
            'address_components',
            'geometry',
            'name',
            'formatted_address',
          ],
        }}
      >
        <div className={`w-full`}>
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-white sr-only"
          >
            Search
          </label>
          <div className="relative">
            <div className="flex absolute inset-y-0 left-0 items-center pl-5 md:pl-5 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-6 h-6 text-[#9D9D9D] z-[1]"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              id="default-search"
              className="block shadow-search-homepage p-3 pl-16 pr-14 w-full text-sm text-black bg-white rounded-full border isolate border-white focus:outline-none google-maps-input"
              placeholder={
                checkIsMobile()
                  ? 'Cari lokasimu'
                  : 'Cari Nama Jalan, Perumahan atau Gedung'
              }
              ref={autoCompleteRef}
            />
            <div
              className="absolute inset-y-0 right-0 items-center pr-5 md:pr-5 z-50 cursor-pointer"
              onClick={() => {
                autoCompleteRef.current.value = ''
              }}
            >
              <MdClose className="w-6 h-6 text-[#9D9D9D]" />
            </div>
          </div>
        </div>
      </Autocomplete>
    </GoogleMap>
  )
}

export default React.memo(GoogleMapsComponent)
